<template>
	<div class="popup active">
		<div class="notice_popup">
			<div class="close">
				<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
			</div>
			<div class="tit">공지사항 삭제</div>
			<div class="member">선택하신 공지사항을 삭제 하시겠습니까?</div>
			<div class="select-btn">
				<div class="btn" @click="$emit('close')">취소</div>
				<div class="btn" @click="$emit('close', true)">예</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style>

</style>